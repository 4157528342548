import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user_id', label: 'ID', sortable: true },
    { key: 'user', label: 'Pic' },
    { key: 'name', label: 'Name', sortable: true},
    { key: 'slug', label: 'Slug', sortable: true },
    { key: 'email', sortable: true },
    { key: 'primaryIndustry', label: 'Industry', sortable: true, formatter: (value, key, item) => {
      return value ? value.name : '-' }},
    { key: 'located', label: 'Location', sortable: true, formatter: (value, key, item) => {
        return value ? value.name : '-' }},
    { key: 'status', label: 'Status', sortable: true },
    { key: 'created_at', label: 'Created at', sortable: true, formatter: (value, key, item) => {
      return value ? new Date(value).toLocaleString(): '-' }},
    { key: 'updated_at', label: 'Updated at', sortable: true, formatter: (value, key, item) => {
        return value ? new Date(value).toLocaleString(): '-' }},
    { key: 'actions' },
  ]
  const userFullList = ref(null)
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const sortBy = ref('user_id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const industryFilter = ref(null)
  const areaFilter = ref(null)
  const filterOn = ref(["user", "name", "email", "primaryIndustry", "located"])
  const industryOptions = ref([])
  const areaOptions = ref([])
  const searchFilters = ref ([])
  const userFilterList = ref(null)

  const clearFilter = () => {
    searchQuery.value = null;
    sortBy.value = 'user_id';
    isSortDirDesc.value = true;
    statusFilter.value = null;
    industryFilter.value = null;
    areaFilter.value = null;
  }

  const { route } = useRouter();

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  onMounted(() => {
    // user list - has filter items
    if (route.value.name === 'apps-users-list' && store.state['app-user'].filterItems) {
      const filterItems = JSON.parse(JSON.stringify(store.state['app-user'].filterItems));
      if (filterItems.length > 0) {
        isSortDirDesc.value = filterItems[0].isSortDirDesc;
        searchQuery.value = filterItems[0].searchQuery;
        sortBy.value = filterItems[0].sortBy;
        statusFilter.value =  filterItems[0].status,
        industryFilter.value =  filterItems[0].industry,
        areaFilter.value =  filterItems[0].location
      }
    }
  });

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  /*update search filter*/
  watch([statusFilter, industryFilter, areaFilter], () => {
    if (statusFilter.value == null && 
      (industryFilter.value == null || industryFilter.value.length == 0) && 
      (areaFilter.value == null || areaFilter.value.length == 0)) {
        searchFilters.value = null  
    } else {
      let searchObj = {};
      if (statusFilter.value !== null) {
        searchObj = {...searchObj, status: statusFilter.value};
      }
      if (industryFilter.value && industryFilter.value.length > 0) {
        searchObj = {...searchObj, primaryIndustry: industryFilter.value}
      }
      if (areaFilter.value && areaFilter.value.length > 0) {
        searchObj = {...searchObj, located: areaFilter.value}
      }
      searchFilters.value = searchObj;
    }
  })

  // custom filter logic
  watch([userFullList, searchFilters], () => {  
    if (searchFilters.value == null) {
      userFilterList.value = userFullList.value
    } else {
      if (userFullList.value) {

        const filtered = userFullList.value.filter(tableRow => {
          return Object.keys(searchFilters.value).every(colKey => {
            if (typeof(searchFilters.value[colKey]) == 'object') {
              if (tableRow[colKey]) {
                // filter with area id to avoid duplicate name
                if (colKey == 'located') {
                  return searchFilters.value[colKey].filter(v => tableRow[colKey]['area_id'] == v).length > 0
                } else {
                  return searchFilters.value[colKey].filter(v => tableRow[colKey]['name'] == v).length > 0
                }
              } else {
                return false
              }
            } else {
              return tableRow[colKey] == searchFilters.value[colKey]
            }
          })
        })
        
        userFilterList.value = filtered
        totalUsers.value = filtered.length
      }
    }
  })

  //table items provider
  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers')
      .then(response => {
        const { users } = response.data
        const modifiedUsers = users.map(user => ({
          ...user, 
          userIndustryUI: user?.primaryIndustry?.name,
          userLocationUI: user?.located?.name,
          statusUI: (user.status==1)?'Active':'Inactive',
        }))
        userFullList.value = modifiedUsers
        totalUsers.value = users.length
        // callback(users)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchIndustryOptions = () => {
    if (store.state['app-user'].industryOptions.length == 0) {
      store
        .dispatch('app-user/fetchIndustryList')
        .then((response) => {
          const { industries } = response.data
          const dataList = industries.map((item) => {
            return { label: item.name, value: item.name }
          })
          store.commit('app-user/SET_INDUSTRY_OPTIONS', dataList)
          industryOptions.value = dataList;
        })
        .catch(() => {})
    } else {
      industryOptions.value = store.state['app-user'].industryOptions;
    }
  }

  const fetchAreaOptions = () => {
    if (store.state['app-user'].areaOptions.length == 0) {
      store
        .dispatch('app-user/fetchAreaList')
        .then((response) => {
          const { areas } = response.data
          const dataList = areas.map((item) => {
            return { label: item.name, value: item.area_id }
          })
          store.commit('app-user/SET_AREA_OPTIONS', dataList)
          areaOptions.value = dataList;      
        })
        .catch(() => {})
    }
    areaOptions.value = store.state['app-user'].areaOptions;
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'Supplier') return 'primary'
    if (role === 'Buyer') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    if (status === 'inreview') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    fetchIndustryOptions,
    fetchAreaOptions,
    userFullList,
    userFilterList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    clearFilter,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    industryFilter,
    areaFilter,
    industryOptions,
    areaOptions,
    filterOn,
    searchQuery,
    searchFilters,
  }
}
