import axios from '@axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    filterItems: null,
    industryOptions: [],
    businessRoleOptions: [],
    areaOptions: [],
    saleableMarketOptions: [],
    modeOptions: [],
    nationalityOptions: [],
    langOptions: [],
    genderDisplay: ["Prefer not to tell", "Male","Female"], // Assume indexed
    genderOptions: [
			{ label: "Prefer not to tell", value: 0 },
			{ label: "Male", value: 1 },
      { label: "Female", value: 2 },
		],
    userStatusDisplay: ["Inactive", "Active", "In Review"], // Assume indexed
    userStatusOptions: [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
      { label: "In review", value: 2 },
    ],
    displayStatusDisplay: ["Inactive", "Active"], // Assume indexed
    displayStatusOptions: [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
    ],
  },
  getters: {
    genderDisplay: state => state.genderDisplay,
    genderOptions: state => state.genderOptions,
    userStatusDisplay: state => state.userStatusDisplay,
    userStatusOptions: state => state.userStatusOptions,
    displayStatusDisplay: state => state.displayStatusDisplay,
    displayStatusOptions: state => state.displayStatusOptions,
  },
  mutations: {
    SET_USER_LIST_FILTER: (state, filterObj) => {
      state.filterItems = filterObj;
    },
    SET_INDUSTRY_OPTIONS: (state, list) => {
      state.industryOptions = list;
    },
    SET_BUSINESSROLE_OPTIONS: (state, list) => {
      state.businessRoleOptions = list;
    },
    SET_AREA_OPTIONS: (state, list) => {
      state.areaOptions = list;
    },
    SET_SALEABLEMARKET_OPTIONS: (state, list) => {
      state.saleableMarketOptions = list;
    },
    SET_MODE_OPTIONS: (state, list) => {
      state.modeOptions = list;
    },
    SET_NATIONALITY_OPTIONS: (state, list) => {
      state.nationalityOptions = list;
    },
    SET_LANG_OPTIONS: (state, list) => {
      state.langOptions = list;
    }
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/api/user/all', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/api/user/profile/id/${id}`, {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchUsersContact(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/api/user/contact/all', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/api/product/all', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateUser(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/update`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateUserContact(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/update/contact`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUserContact(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/add/contact`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUserProduct(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/add/product`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateUserProduct(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/update/product`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateUserProfilePic(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/upload/profilepic`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateUserProductPic(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/upload/productpic`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUserMedia(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/upload/media`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUserMediaLink(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/add/media`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateUserMedia(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/api/user/update/media`, data, {
            headers: {
              Authorization: store.state.auth.authToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchIndustryList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/industry', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchBusinessRoleList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/businessrole', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchAreaList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/area', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchSalebleMarket() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/saleablemarket', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchMode() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/mode', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchNationality() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/nationality', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchLanguage() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/lang', {
            headers: { Authorization: store.state.auth.authToken },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
