<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- TODO: Add back feature -->
    <users-list-filters
      :search-query.sync="searchQuery"
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :industry-filter.sync="industryFilter"
      :area-filter.sync="areaFilter"
      :role-options="roleOptions"
      :user-status-options="userStatusOptions"
      :industry-options="industryOptions"
      :area-options="areaOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
                disabled
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col class="mt-2">
            <b-button
              variant="primary"
              class="float-right"
              @click="clearFilter"
            >
              <span class="text-nowrap">Clear Filter</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="userFilterList"
        :perPage="perPage"
        :current-page="currentPage"
        :fields="tableColumns"
        :filter="searchQuery"
        :sortBy.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        filter-debounce=200
        primary-key="id"
        responsive
        show-empty
        empty-text="No matching records found"
        @filtered="onFiltered"
      >

        <!-- Column: Pic -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.profile_image"
                :text="avatarText(`${data.item.display_name}`)"
                :variant="`light-${resolveUserRoleVariant(data.item.primaryRole ? data.item.primaryRole.name : 'subscriber')}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
              />
            </template>
            <!-- <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ `${data.item.display_name}` }}
            </b-link>
            <small class="text-muted">@{{ data.item.slug }}</small> -->
          </b-media>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ `${data.item.display_name}` }}
          </b-link>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          {{ userStatusDisplay[`${data.item.status}`] }}
        </template>
        

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.user_id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const userStatusDisplay = store.getters['app-user/userStatusDisplay'];
    const roleOptions = [
      { label: 'Admin', value: 'Admin' },
      { label: 'Buyer', value: 'Buyer' },
      { label: 'Editor', value: 'Editor' },
      { label: 'Subscriber', value: 'Subscriber' },
      { label: 'Supplier', value: 'Supplier' },
    ]
    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    const userStatusOptions = store.getters['app-user/userStatusOptions'];

    const {
      userFullList,
      userFilterList,
      fetchUsers,
      fetchIndustryOptions,
      fetchAreaOptions,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      clearFilter,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
      industryFilter,
      areaFilter,
      industryOptions,
      areaOptions,
      filterOn,
      searchQuery,
      searchFilters
    } = useUsersList()

    onMounted(async () => {
      fetchUsers()
      fetchIndustryOptions()
      fetchAreaOptions()
    })

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      userFullList,
      userFilterList,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      clearFilter,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      userStatusDisplay,
      roleOptions,
      planOptions,
      userStatusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      industryFilter,
      areaFilter,
      industryOptions,
      areaOptions,
      filterOn,
      searchQuery,
      searchFilters
    }
  },
  methods: {
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalUsers = filteredItems.length
        this.currentPage = 1
      },
  },
  beforeRouteLeave (to, from, next) {
    // save filter result
    this.$store.commit('app-user/SET_USER_LIST_FILTER', [{
      perPage: this.perPage,
      sortBy: this.sortBy, 
      isSortDirDesc: this.isSortDirDesc,
      searchQuery: this.searchQuery,
      status: this.statusFilter,
      industry: this.industryFilter,
      location: this.areaFilter
    }])
    next();
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
